import React, { HTMLProps } from 'react';
import './Avatar.css';

interface Props extends HTMLProps<HTMLDivElement> {
  image?: string | JSX.Element;
  size?: number;
  fullName?: string;
  contain?: boolean;
  withShadow?: boolean;
}

const Avatar: React.FC<Props> = (props: Props) => {
  const {
    image, size = 30, fullName, style = {}, contain, withShadow, ...other
  } = props;

  const getLetters = (str: string) => str.split(' ')
    .map(word => word.charAt(0).toUpperCase())
    .slice(0, 2)
    .join('');

  return (
    <div
      className={[
        'geecko-avatar',
        image ? '' : 'geecko-avatar--empty',
        contain ? 'geecko-avatar--contain' : '',
        withShadow ? 'geecko-avatar--with-shadow' : '',
      ].join(' ')}
      style={{
        ...style,
        width: size,
        height: size,
        backgroundImage: image ? `url(${image})` : '',
      }}
      {...other}
    >
      {!image && fullName && (
        <span style={{ fontSize: `${size / 3}px`, lineHeight: `${(size / 3) - 1}px` }}>{getLetters(fullName)}</span>
      )}
    </div>
  );
};

export default Avatar;
