import React, { ReactNode, CSSProperties } from 'react';
import './Alert.css';
import classNames from 'classnames';

export type AlertColorType = 'default' | 'warning' | 'danger';

interface Props {
  spacing?: number;
  color?: AlertColorType;
  icon?: ReactNode;
  isNested?: boolean;
  style?: CSSProperties;
}

const Alert: React.FC<Props> = ({
  children, icon, spacing, color = 'warning', isNested, style = {},
}) => {
  const spaced = spacing ? { marginBottom: spacing * 5 } : {};
  return (
    <>
      <div
        className={classNames([
          'geecko-alert',
          color ? `geecko-alert--${color}` : undefined,
          isNested ? 'geecko-alert--nested' : undefined,
        ])}
        style={{ ...spaced, ...style }}
      >
        {icon && (
          <div className="geecko-alert__icon">
            {icon}
          </div>
        )}
        <div className="geecko-alert__content">
          {children}
        </div>
      </div>
    </>
  );
};

export default Alert;
